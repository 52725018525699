import React from "react";
import { Link as RouterLink } from  'react-router-dom';
import './dashboard.css';

const Dashboard = () => {
    return (
        <section className="dashboard-section">
            <RouterLink to="/galleryDashboard">
            <button className="get-started-button">View Gallery</button>
            </RouterLink>
        </section>
    )
}

export default Dashboard;
