import React from "react";
import { Link } from "react-router-dom";
import './works.css';
import Portfolio1 from '../../assests/WhatsApp Image 2024-05-16 at 11.19.23.jpeg';
import Portfolio2 from '../../assests/WhatsApp Image 2024-05-16 at 11.10.40.jpeg';
import Portfolio3 from '../../assests/WhatsApp Image 2024-05-16 at 11.11.55.jpeg';
import Portfolio4 from '../../assests/WhatsApp Image 2024-05-16 at 11.14.07.jpeg';
import Portfolio5 from '../../assests/WhatsApp Image 2024-05-16 at 11.19.28.jpeg';
import Portfolio6 from '../../assests/WhatsApp Image 2024-05-16 at 11.19.32.jpeg';

const Works = () => {
    return (
        <section id="works">
            <h2 className="worksTitle">My Portfolio</h2>
            <span className="worksDesc">At It's  Kasim Production we offer various services in photography, videography, 
                graphic design, web design, renting PA systems, and event decoration and equipment. 
                Welcome and get to interact with our services here at Kasim production website.</span>
            <div className="worksImgs">
                <img src={Portfolio2} alt="" className="worksImg" />
                <img src={Portfolio3} alt="" className="worksImg" />
                <img src={Portfolio4} alt="" className="worksImg" />
                <img src={Portfolio1} alt="" className="worksImg" />
                <img src={Portfolio5} alt="" className="worksImg" />
                <img src={Portfolio6} alt="" className="worksImg" />
            </div>
            <Link to="/gallery">
                 <button className="worksBtn">See More</button>
            </Link>
        </section>
    );
}

export default Works;