import React, { useState } from "react";
import './hireMe.css';
import '../contact/contact.css'
import MyService from "./myservice";
import FacebookIcon from '../../assests/facebook.png';
import TwitterIcon from '../../assests/twitter.png';
import YoutubeIcon from '../../assests/social.png';
import InstagramIcon from '../../assests/instagram.png';


const HireMe = () => {
    const [user, setUser] = useState(
        {
            Name: '', Email: '', Number: '', Time: '', message: ''
        }
    )
    let name, value
    // console.log(user)
    const data = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUser({...user, [name]: value});
    }
    const getdata = async (e) => {
        const {Name, Email, Number, Time, message} = user;
        e.preventDefault();
        const options = {
            method: 'POST',
            header: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Name, Email, Number, Time, message
            })
        }
        const res = await fetch('https://kasim-firegram-default-rtdb.firebaseio.com/UserData.json',
            options
        )
        // console.log(res)
        if (res) {
            alert("Message Sent")
        }
        else 
        {
            alert("Error Occured")
        }
    }
    return (
        <>
        <div className="hire-me">
        <header className="hire-me-header">
            <h1>Hire Me</h1>
            <p>Looking for a skilled developer to bring your project to life? Let's work together!</p>
        </header>

        <section className="services">
            <MyService />
        </section>
        </div>
        <section id="contactPage">
        <div id="contact">
            <h1 className="contactPageTitle">Hire Me</h1>
            <span className="contactDesc">Please fill out the form below to discuss any work opportunities.</span>
            <form className="contactForm" > 
                <input type="text" name="Name" className="name" placeholder="Your Name" value={user.Name} autoComplete="off"  required onChange={data}/>
                <input type="email"  name="Email" className="email" placeholder="Your Email" value={user.Email} autoComplete="off" required onChange={data}/>
                <input type="text" name="Number" className="name" placeholder="Your Number" value={user.Number} autoComplete="off" required onChange={data}/>
                <input type="text" name="Time" className="name" placeholder="Timeline" value={user.Time} autoComplete="off" required onChange={data}/>
                <textarea name="message" className="msg" rows="5" placeholder="Your Message" value={user.message} autoComplete="off" required onChange={data}></textarea>
                <button type="submit" value="send" className="submitBtn" onClick={getdata}> Submit</button>
                    <div className="links">
                    <img src={FacebookIcon} alt="Facebook" className="link" />
                    <img src={TwitterIcon} alt="Twitter" className="link" />
                    <img src={YoutubeIcon} alt="Youtube" className="link" />
                    <img src={InstagramIcon} alt="Instagram" className="link" />
                </div>
            </form>
        </div>
    </section>
    </>
    );
};

export default HireMe;
