import { useState, useEffect, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { photoStorage1, photoFirestore1, photoStorage2, photoFirestore2, timestamp } from "../firebase/configuration";

const useStoragePhoto = (file, useSecondProject = false) => {
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);
    const isUploading = useRef(false);

    const storage = useSecondProject ? photoStorage2 : photoStorage1;
    const firestore = useSecondProject ? photoFirestore2 : photoFirestore1;

    useEffect(() => {
        if (isUploading.current) return;

        isUploading.current = true;

        // Create a storage reference
        const storageRef = ref(storage, file.name);
        const collectionRef = collection(firestore, 'images');

        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', 
            (snapshot) => {
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(percentage);
            }, 
            (err) => {
                setError(err);
                isUploading.current = false;
            }, 
            async () => {
                try {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    const createdAt = timestamp();
                    await addDoc(collectionRef, { url, createdAt });
                    setUrl(url);
                } catch (error) {
                    setError(error);
                } finally {
                    isUploading.current = false;
                }
            }
        );
    }, [file, storage, firestore]);

    return { progress, url, error };
}

export default useStoragePhoto;
