import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { Route, Link as RouterLink, useNavigate } from 'react-router-dom';
import './navbar.css';
import logo from '../../assests/Black production.png';
import contactImg from '../../assests/contact.png';
import menu from '../../assests/icon-menu2.png';
import CustomLink from './CustomLink';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();

    const handleContactClick = () => {
        navigate('/');
        setTimeout(() => {
            const contactElement = document.getElementById('contact');
            if (contactElement) {
                contactElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <nav className="navbar">
            <img src={logo} alt="logo" className="logo" />

            {/* Navigation Links on Desktop */}
            <div className="desktopMenu">
                <CustomLink to="/" scrollTo="intro" className="desktopMenuListItem">Home</CustomLink>
                <CustomLink to="/" scrollTo="skills" className="desktopMenuListItem">About</CustomLink>
                <CustomLink to="/" scrollTo="works" className="desktopMenuListItem">Portfolio</CustomLink>
                <CustomLink to="/" scrollTo="services" className="desktopMenuListItem">Services</CustomLink>
                <RouterLink to="/gallery" className="desktopMenuListItem">Gallery</RouterLink>
                <RouterLink to="/dashboard" className='desktopMenuListItem>'></RouterLink>
                <RouterLink to="hireMe" className='desktopMenuListItem'></RouterLink>
            </div>

            <button className="desktopMenuBtn" onClick={handleContactClick}>
                <img src={contactImg} alt="" className="desktopMenuImg" /> Contact Me
            </button>

            {/* Navigation Links on Mobile */}
            <img src={menu} alt="Menu" className="mobMenu" onClick={() => setShowMenu(!showMenu)} />
            <div className="navMenu" style={{ display: showMenu ? 'flex' : 'none' }}>
                <CustomLink to="/" scrollTo="intro" className="listItem" onClick={() => setShowMenu(false)}>Home</CustomLink>
                <CustomLink to="/" scrollTo="skills" className="listItem" onClick={() => setShowMenu(false)}>About</CustomLink>
                <CustomLink to="/" scrollTo="works" className="listItem" onClick={() => setShowMenu(false)}>Portfolio</CustomLink>
                <CustomLink to="/" scrollTo="services" className="listItem" onClick={() => setShowMenu(false)}>Services</CustomLink>
                <RouterLink to="/gallery" className="listItem" onClick={() => setShowMenu(false)}>Gallery</RouterLink>
                <CustomLink to="/" scrollTo="contact" className="listItem" onClick={() => setShowMenu(false)}>Contact</CustomLink>
            </div>
        </nav>
    );
};

export default Navbar;
