import React from "react";
import './services.css';
import { Link as RouterLink } from "react-router-dom";
import Services_Data from "../../assests/services-data";
import arrow from '../../assests/right-arrow.png';

const Services = () => {
    return (
        <section id="services">     
          <h2 className="services-title">My Services</h2>
            <div className="services-container">
                {Services_Data.map((service, index) => {
                    return <div key={index} className="service-format">
                        <h3>{service.s_no}</h3>
                        <h2>{service.s_name}</h2>
                        <p>{service.s_desc}</p>
                        <RouterLink to={service.route}>
                        <button className="service-readmore">
                            <p>Read More</p>
                            <img src={arrow} alt="arrow-icon" className="arrow-icon" />
                        </button>
                        </RouterLink>
                    </div>
                })}
            </div>
        </section>
    );
}

export default Services;