import React, { useEffect } from "react";
import useStoragePhoto from "../../../hooks/useStoragePhoto";
import { motion } from "framer-motion";

const PhotoProgress = ({ file, setFile, useSecondProject }) => {
    const { url, progress } = useStoragePhoto(file, useSecondProject);

    useEffect(() => {
        if (url) {
            setFile(null);
        }
    }, [url, setFile]);

    return (
        <motion.div className="progress-bar"
            initial={{ width: 0 }}
            animate={{ width: progress + '%' }}
        >

        </motion.div>
    )
}

export default PhotoProgress;
