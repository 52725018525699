import React, { useState } from "react";
import './photograph.css';
import bgImg from '../../../assests/Catherine-Steven-Blog-Karen-Schanely_9083.jpg';
import bgImg1 from '../../../assests/professional-photographer.webp';
import bgImg2 from '../../../assests/bg-3.jpg';
import bookImg from '../../../assests/danie-franco-7XqL_DVuBMw-unsplash.jpg';
import bgIm3 from '../../../assests/samantha-gades-odt2t81dZtQ-unsplash.jpg';
import bgIm4 from '../../../assests/tengyart-5HVsvoH-qYU-unsplash.jpg';
import bgIm5 from '../../../assests/jonathan-borba-n-A894UXsj8-unsplash.jpg';
import UploadPhoto from "./uploadPhoto";
import PhotoGrid from "./photoGrid";
import ModalPhoto from "./modalPhoto";
import Footer from "../../footer/footer";

const Photograph = () => {

    const [selectedImg, setSelectedImg] = useState(null);

    return (
        <div>
            <section className="photo">
                <div className="photoContent">
                    <span className="photoHeader"><h2>CAPTURING THE MAGIC OF YOUR SPECIAL DAY</h2></span>
                    <img src={bgImg} alt="bg-pic" className="photoBg" />
                </div>
            </section>
            <section className="scrollContent">
                <div className="textAndImage">
                    <div className="textContent">
                        <h2 className="scrollHeader">Immortalizing Moments <br /> One Click at a Time</h2>
                        <p className="scrollText">
                            A passionate photographer with a deep love for capturing the beauty and emotions of every special moment. With years of experience in the world of photography, I have honed my skills to create stunning images that tell a unique story.
                        </p>
                        <p className="scrollText">
                            I believe that every event is a work of art waiting to be captured. My approach to photography is inspired by creativity and attention to detail. I strive to make everyone feel special, ensuring that every detail is beautifully documented.
                        </p>
                    </div>
                    <span className="imageContent">
                        <img src={bgImg2} alt="additional-pic" className="sideImage" />
                    </span>
                </div>
                <div className="textAndImage">
                    <div className="textContent">
                        <h3 className="scrollHeader">Let's Create Timeless Memories Together</h3>
                        <p className="scrollText">
                            I am dedicated to creating timeless memories that you will treasure forever.
                            Your day is a once-in-a-lifetime event, and I am committed to preserving it
                            through the art of photography
                        </p>
                    </div>
                    <span className="imageContent">
                        <img src={bgImg1} alt="additional-pic" className="sideHandImage" />
                    </span>
                </div>
            </section>
            <section className="skill">
                <h2 className="skill-title">WHY CHOOSE ME?</h2>
                <div className="skill-content">
                    <div className="skill-msg">
                        <h3 className="skill-hearder">FASHION EXPERTISE</h3>
                        <span className="skill-no">01</span>
                        <p className="skill-para">I have keen eye for fashion and style. I'll ensure you look and feel like a fashion-forward.</p>
                    </div>
                    <div className="skill-msg">
                        <h3 className="skill-hearder">ATTENTION TO DETAIL</h3>
                        <span className="skill-no">02</span>
                        <p className="skill-para">I pay attention to the smallest details, from lighting to compostion, to create perfect shots.</p>
                    </div>
                    <div className="skill-msg">
                        <h3 className="skill-hearder">CREATIVE VISION</h3>
                        <span className="skill-no">03</span>
                        <p className="skill-para">I bring a unique and creative vision to every moment. Your photos will stand out and tell a story.</p>
                    </div>
                    <div className="skill-msg">
                        <h3 className="skill-hearder">PERSONALIZED EXPERIENCE</h3>
                        <span className="skill-no">04</span>
                        <p className="skill-para">I work closely with you to understand your vision and preferences ensuring a personalized photography experience.</p>
                    </div>
                </div>
            </section>
            <section className="blues">
                <div className="textAndImages">
                    <div className="textContents">
                        <h2 className="scrollHeader">WEDDING FASHION <br /> PHOTOGRAPHY</h2>
                        <p className="scrollText">
                            Don't forget the groom! We focus on capturing the style and charisma of the groom, making him look and feel like fashion icon.
                        </p>
                        <button className="bookNowBtn">Book Now</button>
                    </div>
                    <span className="imageContent">
                        <img src={bgIm3} alt="additional-pic" className="sideImage" />
                    </span>
                </div>
                <div className="textAndImagesRep">
                    <div className="textContentsRep">
                        <h2 className="scrollHeader">WEDDING FASHION <br /> PHOTOGRAPHY</h2>
                        <p className="scrollText">
                            Don't forget the groom! We focus on capturing the style and charisma of the groom, making him look and feel like fashion icon.
                        </p>
                        <button className="bookNowBtn">Book Now</button>
                    </div>
                    <span className="imageContent">
                        <img src={bgIm5} alt="additional-pic" className="sideImageRep" />
                    </span>
                </div>
                <div className="textAndImages">
                    <div className="textContents">
                        <h2 className="scrollHeader">WEDDING FASHION <br /> PHOTOGRAPHY</h2>
                        <p className="scrollText">
                            Don't forget the groom! We focus on capturing the style and charisma of the groom, making him look and feel like fashion icon.
                        </p>
                        <button className="bookNowBtn">Book Now</button>
                    </div>
                    <span className="imageContent">
                        <img src={bgIm4} alt="additional-pic" className="sideImage" />
                    </span>
                </div>
            </section>
            <section className="photos-only">
                <h2 className="photonly">LET'S CREATE BEAUTIFUL MEMORIES TOGETHER</h2>
                <UploadPhoto />
                <PhotoGrid setSelectedImg={setSelectedImg} />
                {selectedImg && <ModalPhoto selectedImg={selectedImg} setSelectedImg={setSelectedImg} />}


            </section>

            <section className="bookEvent">
                <div className="bookEventContent">
                    <h2 className="bookEventHeader">BOOK YOUR EVENT PHOTOGRAPHY SESSION TODAY</h2>
                    <form className="bookEventForm">
                        <input type="text" name="name" placeholder="Your Name" className="bookEventInput" />
                        <input type="date" name="date" placeholder="Date" className="bookEventInput" />
                        <button className="bookEventButton">Book Now</button>
                    </form>
                </div>
                <img src={bookImg} alt="book-event" className="bookEventImage" />
            </section>

            <Footer />

        </div>
    );
}

export default Photograph;
