// components/navigation/CustomLink.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const CustomLink = ({ to, children, scrollTo, ...props }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isGalleryPage = location.pathname === '/gallery';
    const isDashboardPage = location.pathname === '/dashboard';
    const isHireMePage = location.pathname === '/hireMe';
    const isPhotographPage = location.pathname === '/photograph';
    const isVideographPage = location.pathname === '/videograph';
    const isGraphicsDesignPage = location.pathname === '/graphics-design';
    const isWebDesignPage = location.pathname === '/web-design';
    const isPAsystemPage = location.pathname === '/pa-system-rental';
    const isEventDecorationPage = location.pathname === '/event-decoration';

    const handleClick = () => {
        if (isGalleryPage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
        
        else if (isDashboardPage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        } 

        else if (isHireMePage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        } 

        else if (isPhotographPage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }         else if (isVideographPage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }         else if (isGraphicsDesignPage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }         else if (isWebDesignPage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }         else if (isPAsystemPage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }         else if (isEventDecorationPage && scrollTo) {
            navigate('/');
            setTimeout(() => {
                const sectionElement = document.getElementById(scrollTo);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        } 
        
        else if (scrollTo) {
            const sectionElement = document.getElementById(scrollTo);
            if (sectionElement) {
                sectionElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            navigate(to);
        }
    };

    return (
        <ScrollLink
            to={scrollTo}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className={props.className}
            onClick={handleClick}
            activeClass="active"
        >
            {children}
        </ScrollLink>
    );
};

export default CustomLink;
