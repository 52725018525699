// src/App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navigation/navbar"; 
import Intro from "./components/intro/intro";
import Skills from "./components/skills/skills";
import Works from "./components/works/works";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import Services from "./components/services/services";
import Gallery from "./components/gallery/gallery";
import Dashboard from "./components/dashboard/dashboard";
import GalleryDashboard from "./components/gallery/galleryDashboard";
import HireMe from "./components/hire/hireMe";
import Photograph from "./components/services/photograph/photograph";
import Videography from "./components/services/videograph/videography";
import Graphics from "./components/services/graphics/graphics";
import WebDesign from "./components/services/webDesign/webdev";
import PaSystem from "./components/services/PASystem/pasystem";
import EventDec from "./components/services/decoration/eventdec";
import SignIn from "./components/auth/signIn";
import SignUp from "./components/auth/signUp";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import { AuthProvider } from "./components/auth/AuthContext";

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={
              <>
                <Intro />
                <Skills />
                <Works />
                <Services />
                <Contact />
                <Footer />
              </>
            } />
            <Route path="hireMe" element={<HireMe />} />
            <Route path="galleryDashboard" element={<ProtectedRoute element={<GalleryDashboard />} />} />
            <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/photograph" element={<Photograph />} />
            <Route path="/videograph" element={<Videography />} />
            <Route path="/graphics-design" element={<Graphics />} />
            <Route path="/web-design" element={<WebDesign />} />
            <Route path="/pa-system-rental" element={<PaSystem />} />
            <Route path="/event-decoration" element={<EventDec />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
