const Services_Data = [
    {
        s_no:"01",
        s_name:"Photograph",
        s_desc:"capture a moment in time that can be looked back on and shared with others...",
        route: "/photograph"
    },
    {
        s_no:"02",
        s_name:"Videograph",
        s_desc:"capture a moment in time that can be looked back on and shared with others...",
        route: "/videograph"
    },
    {
        s_no:"03",
        s_name:"Graphics Design",
        s_desc:"capture a moment in time that can be looked back on and shared with others...",
        route: "/graphics-design"
    },
    {
        s_no:"04",
        s_name:"Web Design",
        s_desc:"capture a moment in time that can be looked back on and shared with others...",
        route: "/web-design"
    },
    {
        s_no:"05",
        s_name:"PA System Rental",
        s_desc:"capture a moment in time that can be looked back on and shared with others...",
        route: "/pa-system-rental"
    },
    {
        s_no:"06",
        s_name:"Event Decoration",
        s_desc:"capture a moment in time that can be looked back on and shared with others...",
        route: "/event-decoration"
    }
];

export default Services_Data;
