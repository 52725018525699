import React, { useRef, useState } from "react";
import './contact.css';
import FacebookIcon from '../../assests/facebook.png';
import TwitterIcon from '../../assests/twitter.png';
import YoutubeIcon from '../../assests/social.png';
import InstagramIcon from '../../assests/instagram.png';
import emailjs from '@emailjs/browser';

const Contact = ()=> {
    const form = useRef();
    const [submitted, setSubmitted] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_mn5g421', 'template_r4frdze', form.current, {
          publicKey: 'w5vHGg0ovYl95dizR',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            e.target.reset();
            setSubmitted(true);
            setTimeout(() => setSubmitted(false), 2000)  //Reset after 2 seconds
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };
    return (
        <section id="contactPage">
            <div id="contact">
                <h1 className="contactPageTitle">Contact Me</h1>
                <span className="contactDesc">Please fill out the form below to discuss any work opportunities.</span>
                <form className="contactForm" ref={form} onSubmit={sendEmail}> 
                    <input type="text" name="user_name" className="name" placeholder="Your Name" required/>
                    <input type="email"  name="user_email" className="email" placeholder="Your Email" required/>
                    <textarea name="message" className="msg" rows="5" placeholder="Your Message" required></textarea>
                    <button type="submit" value="send" className={`submitBtn ${submitted ? 'submitted' : ''}`}> Submit</button>
                        <div className="links">
                        <img src={FacebookIcon} alt="Facebook" className="link" />
                        <img src={TwitterIcon} alt="Twitter" className="link" />
                        <img src={YoutubeIcon} alt="Youtube" className="link" />
                        <img src={InstagramIcon} alt="Instagram" className="link" />
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Contact;