import React from "react";

const PaSystem = () => {
    return (
        <div>
            PA System
        </div>
    )
}

export default PaSystem;