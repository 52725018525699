import { useState, useEffect } from "react";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { photoFirestore1, photoFirestore2 } from "../firebase/configuration";

const useFirestorePhoto = (collectionName, useSecondProject = false) => {
    const [docs, setDocs] = useState([]);
    const firestore = useSecondProject ? photoFirestore2 : photoFirestore1;

    useEffect(() => {
        const q = query(collection(firestore, collectionName), orderBy('createdAt', 'desc'));
        const unsub = onSnapshot(q, (snap) => {
            let documents = [];
            snap.forEach(doc => {
                documents.push({ ...doc.data(), id: doc.id });
            });
            setDocs(documents);
        });

        return () => unsub();
    }, [collectionName, firestore]);

    return { docs };
}

export default useFirestorePhoto;
