import React, { useState } from "react";
import './gallery.css';
import UploadForm from "./uploadForm";
import ImageGrid from "./imageGrid";
import Modal from "./modal";

const GalleryDashboard = () => {

    const [selectedImg, setSelectedImg] = useState(null);

    return (
        <section id="galleries">
            <h2>My Pictures</h2>
            <p>Its_Kasim multimedia production provide high quality products.</p>
            <UploadForm />
            <ImageGrid setSelectedImg={setSelectedImg} />
            { selectedImg && <Modal selectedImg={selectedImg}  setSelectedImg={setSelectedImg} /> }
        </section>
    )
}

export default GalleryDashboard;