import React from "react";

const EventDec = () => {
    return (
        <div>
            Event Decoration
        </div>
    )
}

export default EventDec;