import React, { useState } from "react";
import './videography.css';
import { Link as RouterLink } from "react-router-dom";
import Footer from "../../footer/footer";
import videoBg from '../../../assests/video1_merged-vmake(1).mp4';
import imgGd from '../../../assests/bg-4.jpg';
import filming from '../../../assests/filming.png';
import equipment from '../../../assests/equipment-hire.png';
import workshop from '../../../assests/workshop.png';
import video01 from '../../../assests/video-1.mp4';
import video02 from '../../../assests/video-2.mp4';
import video03 from '../../../assests/video-3.mp4';
import video04 from '../../../assests/video4.mp4';
import video05 from '../../../assests/video5.mp4';
import video06 from '../../../assests/video6.mp4';

const Videography = () => {
    const [selectedVideo, setSelectedVideo] = useState(null);

    const videos = [
        video01, video02, video03, video04, video05, video06
    ];

    const handleVideoClick = (video) => {
        setSelectedVideo(video);
    };

    const closeModal = () => {
        setSelectedVideo(null);
    };

    return (
        <div>
            <section className="videography-section">
                <div className="video-container">
                    <div className="video-overlay"></div>
                    <video className="background-video" src={videoBg} autoPlay loop muted/>
                    <div className="video-content">
                        <div className="video-headings">
                            <h3>FILM</h3><span>||</span>
                            <h3>VIDEO</h3><span>||</span>
                            <h3>ANIMATION</h3>
                        </div>
                        <h1>A Good Ending is The <br/> Most Important</h1>
                        <div className="video-buttons">
                            <button className="view-project-button">View Project</button>
                            <button className="book-now-button">Book Now</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="info-section">
                <div className="info-content">
                    <img src={imgGd} className="info-image" alt="Graphics Design" />
                    <div className="info-text">
                        <span className="info-span"></span>
                        <h3>SINCE 2020</h3>
                        <h3>I'm a creative film and video production company based in Kenya</h3>
                        <span className="info-span"></span>
                        <p>We are a creative film and photo production company hungry for quality in aesthetics. 
                            To create modern recognizable stuff we are working with a strong network of experienced professionals.
                            We set up teams to shape your identity, push your idea and manage the workflow from pre- to post-production.
                        </p>
                    </div>
                </div>
            </section>

            <section className="filming-services-section">
                <div className="intro">
                    <h1>Filming Services</h1>
                    <span className="short-line"></span>
                    <p>I offer different services such as filming services, workshop & private tuition, equipment hire. If you have any question, don't hesitate to contact me.</p>
                </div>

                <div className="all-in-all">
                    <div className="service-item">
                        <span>
                            <img src={filming} className="service-image" alt="" />
                        </span>
                        <h4>Filming Services</h4>
                        <p>I can film your project whether it is a commercial advertise, a short film or a document film. I have expertise in these fields.</p>
                    </div>

                    <div className="service-item">
                        <span>
                            <img src={workshop} className="service-image" alt="" />
                        </span>
                        <h4>Workshops & Private Tuition</h4>
                        <p>Some workshops have invited me to come lecturing. I also offer private tuitions for absolute beginners or mid-level producers who need more advice.</p>
                    </div>

                    <div className="service-item">
                        <span>
                            <img src={equipment} className="service-image" alt="" />
                        </span>
                        <h4>Equipment Hire</h4>
                        <p>Sometimes I don’t use my equipment so you can hire them for your filming. Check my schedule and contact if it suits your time.</p>
                    </div>
                </div>
            </section>

            <section className="featured-films-section">
                <div className="container">
                    <h2>Featured Films</h2>
                    <span></span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus elit condimentum vitae. Maecenas eget orci vitae enim accumsan mollis.</p>
                    <div className="video-grid">
                        {videos.map((video, index) => (
                            <div key={index} className="video-thumbnail" onClick={() => handleVideoClick(video)}>
                                <video className="thumbnail-video" src={video} muted />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {selectedVideo && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close" onClick={closeModal}>&times;</span>
                        <video className="modal-video" src={selectedVideo} controls autoPlay />
                    </div>
                </div>
            )}

            <section className="workout">
                <div className="work-msg">
                    <h6>So What's Next?</h6>
                    <h4>Are You Ready? <RouterLink to="/contact" ><span>Let's Work!</span></RouterLink></h4>
                </div>
            </section>

            <Footer />

        </div>
    );
};

export default Videography;
