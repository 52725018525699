import React from "react";
import '../skills/skills.css';
import camera from '../../assests/camera(1).png';
import video from '../../assests/action-camera.png';
import graphic from '../../assests/graphic-designer.png';
import website from '../../assests/developer.png';
import deejay from '../../assests/dj.png';
import paint from '../../assests/paint-roller.png';

const MyService = () => {
    return (
        <section id="skills">

        <div className="skillBars">
              
                <div className="skillBar">
                    <img src={camera} alt="camera" className="skillBarImg" />
                    <div className="skillBarTxt">
                        <h2>Photography</h2>
                        <p>Capturing Life's Moments Through the Lens</p>
                    </div>
                </div>

                <div className="skillBar">
                    <img src={video} alt="video" className="skillBarImg" />
                    <div className="skillBarTxt">
                        <h2>Videography</h2>
                        <p>Bringing Stories to Life Through Video</p>
                    </div>
                </div>

                <div className="skillBar">
                    <img src={graphic} alt="graphic" className="skillBarImg" />
                    <div className="skillBarTxt">
                        <h2>Graphic Design</h2>
                        <p>Designing Your Vision Into Reality</p>
                    </div>
                </div>

                <div className="skillBar">
                    <img src={website} alt="graphic" className="skillBarImg" />
                    <div className="skillBarTxt">
                        <h2>Web Design</h2>
                        <p>Building dynamic websites for online success.</p>
                    </div>
                </div>

                <div className="skillBar">
                    <img src={deejay} alt="graphic" className="skillBarImg" />
                    <div className="skillBarTxt">
                        <h2>PA System Rental</h2>
                        <p>Professional sound solutions for events of all sizes.</p>
                    </div>
                </div>

                <div className="skillBar">
                    <img src={paint} alt="graphic" className="skillBarImg" />
                    <div className="skillBarTxt">
                        <h2>Event Decoration & Equipment</h2>
                        <p>Bring your vision to life with creative flair and attention to detail.</p>
                    </div>
                </div>

            </div>
            </section>
    )
}

export default MyService;