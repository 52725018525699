// Import the necessary functions from the Firebase SDKs
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore, serverTimestamp } from 'firebase/firestore'; // Import serverTimestamp
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWE0XhdczaO4EHQeUzZvo33ZRtWEwsnX4",
  authDomain: "kasim-firegram.firebaseapp.com",
  projectId: "kasim-firegram",
  storageBucket: "kasim-firegram.appspot.com",
  messagingSenderId: "298582066595",
  appId: "1:298582066595:web:44fe41275679f1ac598616"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const galleryStorage = getStorage(app);
const galleryFirestore = getFirestore(app);
const auth = getAuth(app);
const timestamp = serverTimestamp; // Corrected timestamp initialization

export { galleryStorage, galleryFirestore, auth, timestamp };
