import { initializeApp, getApps, getApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore, serverTimestamp } from 'firebase/firestore';

// Configuration for the first Firebase project
const firebaseConfig1 = {
    apiKey: "AIzaSyASk5RBNfYI4oYGqoFg3N7IH7FudLhYop8",
    authDomain: "awesome-f60d0.firebaseapp.com",
    projectId: "awesome-f60d0",
    storageBucket: "awesome-f60d0.appspot.com",
    messagingSenderId: "656281776313",
    appId: "1:656281776313:web:d1d73becba318bbdd82b68"
};

// Configuration for the second Firebase project
const firebaseConfig2 = {
    apiKey: "AIzaSyAbiYqGwFp2e2gjzVbsvODYoyb7eLVVGSM",
    authDomain: "photograph-kasim-firegram.firebaseapp.com",
    projectId: "photograph-kasim-firegram",
    storageBucket: "photograph-kasim-firegram.appspot.com",
    messagingSenderId: "449475631715",
    appId: "1:449475631715:web:198b32d11f6b29d3bbf656"
  };

// Initialize Firebase apps if not already initialized
const app1 = !getApps().some(app => app.name === 'app1') ? initializeApp(firebaseConfig1, 'app1') : getApp('app1');
const app2 = !getApps().some(app => app.name === 'app2') ? initializeApp(firebaseConfig2, 'app2') : getApp('app2');

// Initialize services for both apps
const photoStorage1 = getStorage(app1);
const photoFirestore1 = getFirestore(app1);

const photoStorage2 = getStorage(app2);
const photoFirestore2 = getFirestore(app2);

const timestamp = serverTimestamp;

export { photoStorage1, photoFirestore1, photoStorage2, photoFirestore2, timestamp };
