import { useState, useEffect, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore"; // Import necessary Firestore functions
import { galleryStorage, galleryFirestore, timestamp } from "../firebase/config";

const useStorage = (file) => {
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);
    const isUploading = useRef(false);

    useEffect(() => {
        if (isUploading.current) return;

        isUploading.current = true;

        // Create a storage reference
        const storageRef = ref(galleryStorage, file.name);
        const collectionRef = collection(galleryFirestore, 'images'); // Corrected collection reference

        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', 
            (snapshot) => {
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(percentage);
            }, 
            (err) => {
                setError(err);
                isUploading.current = false;
            }, 
            async () => {
                try {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    const createdAt = timestamp(); // Use Firestore server timestamp
                    await addDoc(collectionRef, { url, createdAt }); // Add document to Firestore collection
                    setUrl(url);
                } catch (error) {
                    setError(error);
                } finally {
                    isUploading.current = false;
                }
            }
        );
    }, [file]);

    return { progress, url, error };
}

export default useStorage;
 