import React from "react";
import "./graphics.css";
import Footer from "../../footer/footer";
import project1 from "../../../assests/WhatsApp Image 2024-05-16 at 11.02.50.jpeg";
import project2 from "../../../assests/WhatsApp Image 2024-05-16 at 11.03.00.jpeg";
import project3 from "../../../assests/WhatsApp Image 2024-05-16 at 11.02.56.jpeg";
import vector from "../../../assests/graphic-01.jpeg";
import logo1 from "../../../assests/logo6.png";
import logo2 from "../../../assests/logo1.jpeg";
import logo3 from "../../../assests/logo5.jpeg";
import logo4 from "../../../assests/ai1.jpeg";
import logo5 from "../../../assests/logo3.jpeg";

const Graphics = () => {
  return (
    <div className="graphics-page">
      <header className="graphics-header">
        <div>
          <h1>Excellence in Every Pixel</h1>
          <h2>
            Professional Graphics Design <br /> & Photo Manipulation
          </h2>
          <p>
            Thinking about enhancing your visual content? Whether you need
            custom graphics design <br /> or expert photo manipulation, we offer
            high-performance solutions tailored to your needs.
            <br /> Our team combines creativity and precision to deliver
            stunning visuals that captivate and <br /> engage your audience.
            With a commitment to quality and detail, we transform your ideas{" "}
            <br /> into compelling designs that elevate your brand and make a
            lasting impact. Let us help you <br /> create visuals that not only
            look great but also drive results.
          </p>
        </div>
        <div className="vector-container">
          <img src={vector} className="vector-img" alt="illustration" />
        </div>
      </header>

      <section className="designer-intro">
        <div className="speech">
          <p>
            Hey, there! Also I’m a Graphics Designer and Photo Manipulator based
            in Nairobi, Kenya.
            <br />I focus on creating stunning visual designs and manipulating
            photos to achieve artistic and professional results.
          </p>
          <button className="contact-button">More About Me</button>
        </div>
      </section>

      <section className="graphics-skills">
        <h2>Graphic Design Skills & Software</h2>
        <p>
          I have honed my skills in various areas of graphic design, including:
        </p>
        <div className="graphics-main">
          <h3>Logo Design</h3>
          <span>||</span>
          <h3>Brand Identity</h3>
          <span>||</span>
          <h3>Illustrations</h3>
          <span>||</span>
          <h3>Photo Manipulation</h3>
          <span>||</span>
          <h3>Social Media Graphics</h3>
          <span>||</span>
          <h3>Print Design</h3>
        </div>
        <div className="graphics-resources">
          <p>The software I use to bring my designs to life includes:</p>
          <div className="graphics-logo">
            <img src={logo1} className="logos" alt="icon" />
            <img src={logo2} className="logos" alt="icon" />
            <img src={logo3} className="logos" alt="icon" />
            <img src={logo4} className="logos" alt="icon" />
            <img src={logo5} className="logos" alt="icon" />
          </div>
        </div>
      </section>

      <div className="project-intro">
        <h1 className="project-head">My Projects</h1>
        <p className="project-desc">
          Discover my graphic design projects that turn creative concepts into
          visually stunning realities. From brand identity to digital
          illustrations, my work showcases a blend of artistry and technical
          skill.
        </p>
      </div>
      <section className="graphics-section">
        <div className="graphics-item">
          <img src={project1} alt="Project 1" />
          <div className="graphics-description">
            <h2>Social Media Influencer Poster</h2>
            <p>
              Poster design for an influencer who achieved 1.4 million likes and
              100k followers, capturing the essence of their social media.
            </p>
          </div>
        </div>
        <div className="graphics-item">
          <img src={project2} alt="Project 2" />
          <div className="graphics-description">
            <h2>Delicious Food Poster</h2>
            <p>
              A vibrant and appetizing poster designed to showcase the culinary
              delights and entice food lovers with visually appealing graphics.
            </p>
          </div>
        </div>
        <div className="graphics-item">
          <img src={project3} alt="Project 3" />
          <div className="graphics-description">
            <h2>Graphics Design Poster</h2>
            <p>
              An artistic poster highlighting the creativity and versatility in
              graphic design, demonstrating a range of skills and styles.
            </p>
          </div>
        </div>
        <div className="graphics-item">
          <img src={project1} alt="Project 1" />
          <div className="graphics-description">
            <h2>Social Media Influencer Poster</h2>
            <p>
              Poster design for an influencer who achieved 1.4 million likes and
              100k followers, capturing the essence of their social media.
            </p>
          </div>
        </div>
        <div className="graphics-item">
          <img src={project2} alt="Project 2" />
          <div className="graphics-description">
            <h2>Delicious Food Poster</h2>
            <p>
              A vibrant and appetizing poster designed to showcase the culinary
              delights and entice food lovers with visually appealing graphics.
            </p>
          </div>
        </div>
        <div className="graphics-item">
          <img src={project3} alt="Project 3" />
          <div className="graphics-description">
            <h2>Graphics Design Poster</h2>
            <p>
              An artistic poster highlighting the creativity and versatility in
              graphic design, demonstrating a range of skills and styles.
            </p>
          </div>
        </div>
        <div className="graphics-item">
          <img src={project1} alt="Project 1" />
          <div className="graphics-description">
            <h2>Social Media Influencer Poster</h2>
            <p>
              Poster design for an influencer who achieved 1.4 million likes and
              100k followers, capturing the essence of their social media.
            </p>
          </div>
        </div>
        <div className="graphics-item">
          <img src={project2} alt="Project 2" />
          <div className="graphics-description">
            <h2>Delicious Food Poster</h2>
            <p>
              A vibrant and appetizing poster designed to showcase the culinary
              delights and entice food lovers with visually appealing graphics.
            </p>
          </div>
        </div>
        <div className="graphics-item">
          <img src={project3} alt="Project 3" />
          <div className="graphics-description">
            <h2>Graphics Design Poster</h2>
            <p>
              An artistic poster highlighting the creativity and versatility in
              graphic design, demonstrating a range of skills and styles.
            </p>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <div className="contact-container">
          <h2 className="contact-head">Get in Touch</h2>
          <p className="contact-desc">
            If you're interested in working together or have any further
            questions, feel free to contact us.
          </p>
          <button className="contact-button">&#128172; Let's Talk!</button>
        </div>
      </section>

      <Footer />

    </div>
  );
};

export default Graphics;
