import React from "react";
import './intro.css';
import { Link as RouterLink} from "react-router-dom";
import btnImg from '../../assests/briefcase.png';
import img from '../../assests/man-to-man.png';
// import { Link } from "react-scroll";


const Intro = () => {
    return (
        <section id="intro">
            <div className="introContent">
                <span className="greeting">Hello,</span>
                <span className="introText">I'm <span className="introName">Kasim</span> <br/> Multimedia Artist</span>
                <p className="introPara">I am a skilled multimedia artist with a passion for capturing life's most precious moments through <br/> photography,
                 videography, and graphic design. With years of experience in the industry, I <br/>specialize in delivering stunning visuals that tell stories 
                 and evoke emotions.</p>
                <RouterLink to="/hireMe"><button className="btn"><img src={btnImg} alt="btnImg" className="btnImg" />Hire Me</button></RouterLink>
            </div>
            <img src={img} alt="profile" className="bg" />

        </section>
    )
}

export default Intro;